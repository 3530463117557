import IconDisabled from 'assets/general/circle.inline.svg';
import IconSend from 'assets/general/circle-send.inline.svg';
import Button from 'components/material-ui/Button';
import PropTypes from 'prop-types';
import React from 'react';
import tw from 'twin.macro';

export default function ButtonIconLeft({ name, Icon, disabled, size, width, height, loading, children, type, ...props }) {
	const isMedium = size === 'medium';

	return (
		<Button
			data-cy={props['data-cy'] ?? `ButtonIconLeft-${name}`}
			mode="icon-left"
			loading={loading}
			disabled={disabled}
			sizeCircularProgress={30}
			css={[
				loading && tw`pr-03`,
				disabled && tw`bg-grey-E text-grey-9`,
				size === 'medium' && tw`w-100 text-10 pr-05`
			]}
			type={type}
			{...props}
		>
			<div className="w-full flex items-center justify-between">
				{disabled ? (
					<IconDisabled width={isMedium ? '2.5rem' : width} height={isMedium ? '2.5rem' : height}/>
				) : (
					<Icon width={isMedium ? '2.5rem' : width} height={isMedium ? '2.5rem' : height}/>
				)}
				<div className="mx-auto">
					<div>{children}</div>
				</div>
			</div>
		</Button>
	);
}

ButtonIconLeft.propTypes = {
	name: PropTypes.string.isRequired
};

ButtonIconLeft.defaultProps = {
	Icon: IconSend,
	size: 'normal',
	width: '3rem',
	height: '3rem'
};
