import PricingPage from 'components/pages/pricing/PricingPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Cennik i plany | FINTEQ'
			keywords='cena, koszt, specyfikacja usługi, kup finteq, dostępne plany, porównaj ofertę, wybierz plan, możliwości finteq'
			description='Sprawdź ceny i wybierz plan, który najbardziej Ci odpowiada. Porównaj dostępne opcje i podejmij najlepszą decyzję w swoim życiu.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<PricingPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
